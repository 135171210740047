import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNElement, OHNCalendarEvent } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnService } from '../../../services/ohn.service';
import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';

import { CalendarEventViewPage } from '../../../secured/calendar-event-view/calendar-event-view.page';

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html',
  styleUrls: ['./calendar-widget.component.scss'],
})
export class CalendarWidgetComponent implements OnInit {

	@Input() el: OHNElement;
  @Input() refresher : Subject<boolean>;

  slideOpts : any = {
    initialSlide: 1,
    slidesPerView: 1.2
  };

	events: OHNCalendarEvent[];

	calendarElement: OHNElement;

	loading: boolean = false;



  constructor(
  	private ohnApi: OhnApiService,
    private ohnService: OhnService,
  	public modalController: ModalController
  ) { }

  ngOnInit() {
  	console.log(this.el);
  	this.loadCalendarState();
  	this.loadCalendarElement();
    this.refresher.subscribe(refreshing => {
      this.loadCalendarState();
      this.loadCalendarElement();
    });
  }

  loadCalendarState() {
  	this.loading = true;
  	this.ohnApi.getElementHistory(this.el.config.calendar_slug, 'today', 1).subscribe(state => {
      this.events = _.isArray(state) ? _.map(state, (s)=>{return new OHNCalendarEvent(s)}) : [];
      this.loading = false;
    });
  }

  loadCalendarElement() {
  	this.ohnApi.getElement(this.el.config.calendar_slug, 3).subscribe(calendar => {
      this.calendarElement = calendar;
    });
  }

  setRate(rating: number, event: OHNCalendarEvent) {
    event.rating = rating;
    this.ohnApi.patchElementState(this.el.config.calendar_slug, {value: {id : event.id, rating: rating}}).subscribe(state => {
      //console.log(state);
    });
    //console.log(event);
  }

  async openEventModal(event: any) {
    const eventPage = await this.modalController.create({
      component: CalendarEventViewPage,
      componentProps: {
      	'calendarElement' : this.calendarElement,
      	'event' : event
	    },
      backdropDismiss: false
    });

    eventPage.onDidDismiss().then((data) => {
    	//if (data.data.newEvent) this.eventSource.push(data.data.newEvent);
    	//console.log(data);
    	//this.eventCalendar.loadEvents();
		})

    return await eventPage.present();
  }

  openEventDetails(event: any) {
  	this.openEventModal(event);
  }

}
