import { Component, OnInit, Input } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ToastController } from '@ionic/angular';
import { OHNElement, OHNCalendarEvent, OHNMedication, OHNMeeting } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../services/ohn-api.service';
import { OhnService } from '../../../services/ohn.service';
import * as _ from 'underscore/underscore';
import { Subject} from 'rxjs';


@Component({
  selector: 'app-meetings-widget',
  templateUrl: './meetings-widget.component.html',
  styleUrls: ['./meetings-widget.component.scss'],
})
export class MeetingsWidgetComponent implements OnInit {

	@Input() el: OHNElement;
  @Input() refresher : Subject<boolean>;

  intakes: OHNMeeting[];

	calendarElement: OHNElement;

	loading: boolean = false;

  constructor(
  	private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private toastController : ToastController,
    private iab: InAppBrowser
  ) { }

  ngOnInit() {
  	console.log(this.el);
  	this.loadCalendarHistory();
  	this.loadCalendarElement();
  	this.ohnService.widgetRefresher.subscribe(refreshing => {
      this.loadCalendarHistory();
      this.loadCalendarElement();
    });
    this.refresher.subscribe(refreshing => {
      this.loadCalendarHistory();
      this.loadCalendarElement();
    });
  }

  loadCalendarHistory() {
  	this.loading = true;
  	this.ohnApi.getElementHistory(this.el.config.medication_slug, 'day', 0).subscribe(history => {
      console.log(history);
      let meetings: any[] = (history && _.isArray(history)) ? _.filter(history, (h)=>{return h.controller == 'meeting'}) : [];
      this.intakes = _.map(meetings, (s)=>{return new OHNMeeting(s, true, true)});
      this.loading = false;
    });
  }

  loadCalendarElement() {
  	this.ohnApi.getElement(this.el.config.medication_slug, 3).subscribe(calendar => {
      this.calendarElement = calendar;
    });
  }

  takeMedicine(intake : OHNMeeting) {
    this.intakes = _.filter(this.intakes, (i)=>{return i.id != intake.id});
    this.notifyOfIntake(intake);
    this.ohnApi.patchElementState(this.calendarElement.element_slug, {value: {id : intake.id, completed: true}}).subscribe(state => {
      console.log(state);
    });
  }

  openMeetingLink(intake : OHNMeeting) {
  	//window.open(intake.contentObject.url, '_system');
  	const browser = this.iab.create(intake.contentObject.url, '_system');
  }

  async notifyOfIntake(prescription: OHNMeeting) {
    const toast = await this.toastController.create({
      message: '<strong>'+ prescription.title +' : has been marked as visited.',
      duration: 2000
    });
    toast.present();
  }

}
