import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, ToastController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { NewMeetingComponent } from '../new-meeting/new-meeting.component';
import { OHNElement, OHNUser, OHNCalendarEvent, OHNMedication, OHNMeeting } from '../../models/ohn-instances';
import { Subject} from 'rxjs';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-meetings-editor',
  templateUrl: './meetings-editor.component.html',
  styleUrls: ['./meetings-editor.component.scss'],
})

export class MeetingsEditorComponent implements OnInit {

	@Input() user: OHNUser;
	@Input() meetingCalendarObject: OHNElement;
  @Input() calendarUpdater: Subject<any>;

  meetings : OHNMeeting[];

  loading : boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
    private modalController : ModalController,
    private alertCtrl: AlertController,
    private toastController: ToastController
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadMeetings();
  }

  loadMeetings() {
    this.ohnApi.getElementStateRepeatableSc(this.meetingCalendarObject.element_slug, this.user.smart_contract).subscribe(state => {
      this.meetings = (state.value &&_.isArray(state.value)) ? _.map(state.value, (s)=>{return new OHNMeeting(s)}) : [];
      this.meetings = _.filter(this.meetings, (m)=>{return m.controller == 'meeting'});
      console.log(this.meetings);
      this.loading = false;
    });
  }

  async openMeetingModal(isNew: boolean, meeting: OHNMeeting) {
    const editMedPage = await this.modalController.create({
      component: NewMeetingComponent,
      componentProps: {
        'calendarElement' : this.meetingCalendarObject,
        'user' : this.user,
        'isNew' : isNew,
        'editMeeting' : meeting
      },
      backdropDismiss: false
    });

    editMedPage.onDidDismiss().then((data) => {
      this.calendarUpdater.next();
      this.ohnService.widgetRefresher.next();
      if (data.data.isNew) {
        this.meetings.push(data.data.event)
      }
    })

    return await editMedPage.present();
  }

  async showDeleteDialog(meeting : OHNMeeting) {
   const alert = await this.alertCtrl.create({
      header: 'Delete Meeting',
      message: 'Are you sure you want to delete <strong>'+ meeting.title +'</strong>?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
           
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.delete(meeting);
          }
        }
      ]
    });

    await alert.present();
  }

  async notifyOfDeletion(meeting: OHNMeeting) {
    const toast = await this.toastController.create({
      message: '<strong>'+ meeting.title +'</strong> has been deleted.',
      duration: 2000
    });
    toast.present();
  }

  delete(meeting: OHNMeeting){
    this.meetings = _.filter(this.meetings, (p)=>{return p.id != meeting.id});
    this.ohnApi.deleteElementStateSc(this.meetingCalendarObject.element_slug, {value: {id : meeting.id}}, this.user.smart_contract).subscribe(event => {
      this.notifyOfDeletion(meeting);
    });
  }

}