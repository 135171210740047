import { AuthGuardService } from './services/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: 'login', loadChildren: './public/login/login.module#LoginPageModule' },
  { path: 'login', redirectTo: 'home', pathMatch: 'full' },
  { path: 'products', redirectTo: 'products/patient-sphere', pathMatch: 'full' },
  { 
    path: 'secured', 
    canActivate: [AuthGuardService],
    loadChildren: './secured/secured-routing.module#SecuredRoutingModule'
  },
  {
    path: 'offerings',
    loadChildren: () => import('./public/offerings/offerings.module').then( m => m.OfferingsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./public/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'products/:id',
    loadChildren: () => import('./public/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'features',
    loadChildren: () => import('./public/features/features.module').then( m => m.FeaturesPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./public/privacy/privacy.module').then( m => m.PrivacyPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
