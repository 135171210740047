// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

//export const API_URL = 'http://http-nutromatic-ocn-io-3.moesif.net';
export const API_URL = 'https://www-api.ocn.io/uptime';
export const APP_SLUG = 'patientspherex';

export const BASIC_HEADERS = {
	'Content-Type':  'application/json'//,
	//'X-Moesif-Application-Id' : 'eyJhcHAiOiIzNDU6MjM3IiwidmVyIjoiMi4wIiwib3JnIjoiMzUxOjEyMiIsImlhdCI6MTUzMDQ4OTYwMH0.CMrVQ1tjEZFNdcu-RpsTYFlHj7UeD9fBw60ufk3_zLs',
}

//export const APP_FOOTNOTE = 'First time users - <a href="https://docs.google.com/forms/d/e/1FAIpQLSeumzicyaApgWldw8FHMxrC19j1BK3WBA7nGZHoqKBcFoT80A/viewform?usp=sf_link" target="_blank">click here</a> to request access.';

//export const APP_OVERVIEW = '<h1><strong>ConstantCare for COVID</strong> is an offering by <strong><a href="https://www.openhealth.cc/" target="_blank">Open Health Network</a></strong> that enables physicians to:</h1><ul><li>See all their patients and their tracked symptoms on a dashboard</li><li>Patients are triaged and tagged based on symptoms</li><li>Data recorded by patients via mobile app is immediately available</li><li>Doctor can add notes to each patient’s record</li><li>Detailed report for each patient is available</li></ul>';


export const APP_FOOTNOTE = '';

export const APP_OVERVIEW = '';



export const NOT_AUTHORIZED_MESSAGE = 'You are not authorized to use this app please use <a href="https://docs.google.com/forms/d/e/1FAIpQLSeumzicyaApgWldw8FHMxrC19j1BK3WBA7nGZHoqKBcFoT80A/viewform?usp=sf_link" target="_blank">this form</a> to request access';


export const APP_AVAILABLE_FOR_ROLES = ['practitioner', 'admin', 'patient', 'user'];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.