import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { OhnApiService } from './services/ohn-api.service';
import { OhnService } from './services/ohn.service';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { OHNElement, OHNUser } from './models/ohn-instances';
import { APP_AVAILABLE_FOR_ROLES, NOT_AUTHORIZED_MESSAGE } from '../environments/environment';

import * as _ from 'underscore/underscore';

export interface Application extends OHNElement {
  menu_elements: [OHNElement],
  home_page: string
}
 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html' 
})

export class AppComponent {

  app : Application;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private router: Router
  ) {
    this.ohnApi.tokenReceivedFromStorage.subscribe(received => {
      if (received) this.getMe();
    });
    this.initializeApp();
  }
 
  initializeApp() {
    /*
    this.authenticationService.authenticationState.subscribe(state => {
        if (!state) this.router.navigate(['home']);
    });
    */
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  getMe(){
    this.ohnApi.getMe().subscribe(me => {
      this.loadAppByRole(me);
    });
  }

  loadAppByRole(me: OHNUser) {
    /*if (me.roles.length > 1) {
      this.getSubApp('patientsphere_practitioner');
    } else {
      this.getApp();
    }*/
    //this.getApp();
    let appAvailable : boolean = false;
    _.each(APP_AVAILABLE_FOR_ROLES, (r)=>{
      if (_.contains(me.roles, this.ohnService.appRoleModifier(r, true))) {
        appAvailable = true;
      }
    });
    if (appAvailable) {
      //this.getSubApp('patientsphere_practitioner');
      if(_.contains(me.roles, this.ohnService.appRoleModifier('admin', true))) {
        this.getSubApp(this.ohnService.appRoleModifier('admin', true));
      } else {
        this.getApp();
      }
    } else {
      this.ohnService.showAlert('Not authorized', NOT_AUTHORIZED_MESSAGE);
    }
  }

  getSubApp(role: string) {
    this.ohnApi.getElement('app_' + role, 2).subscribe(application => {
      this.app = application;
        this.loadPageComponent(this.getHomePage());
    });
  }

  getApp() {
    this.ohnApi.getApp().subscribe(application => {
      this.app = application;
        this.loadPageComponent(this.getHomePage());
    });
  }

  getHomePage() {
    return _.find(this.app.elements, (e)=>{return e.element_slug == this.app.home_page})
  }

  loadPageComponent(element: OHNElement) {
    this.router.navigate(['secured', element.controller, element.element_slug]);
  }

  
}