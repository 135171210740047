import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNCalendarActionList, OHNCalendarAction } from '../../models/ohn-instances';
import { OhnService } from '../../services/ohn.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-calendar-action-list-manager',
  templateUrl: './calendar-action-list-manager.component.html',
  styleUrls: ['./calendar-action-list-manager.component.scss'],
})
export class CalendarActionListManagerComponent implements OnInit {

	@Input() actionList: OHNCalendarActionList;
	@Input() contentModeEditable : boolean;

  constructor(
    private ohnService: OhnService
  ) { }

  ngOnInit() {
  	console.log(this.actionList);
  }

  newDescriptionElement(element: OHNElement){
  	let el : OHNElement = _.clone(element);
  	this.actionList.description.push(el);
  	console.log(this.actionList);
  }

  deleteActionListDescriptionElement(elementIndex: number) {
  	this.actionList.description.splice(elementIndex, 1);
  }

  newActionDescriptionElement(element: OHNElement, actionIndex: number ){
  	let el : OHNElement = _.clone(element);
  	this.actionList.actions[actionIndex].description.push(el);
  }

  deleteActionDescriptionElement(elementIndex: number, actionIndex: number) {
  	this.actionList.actions[actionIndex].description.splice(elementIndex, 1);
  }

  newAction() {
  	this.actionList.actions.push(
  		<OHNCalendarAction>{
  			text : "",
				description:[],
				done : false,
				trackingParameters : [],
				repeat : 1,
        id : OhnService.getUniqueId(this.actionList.actions)
  		}
  	);
  }

  deleteAction(actionIndex: number) {
  	this.actionList.actions.splice(actionIndex, 1);
  }

  newTrackingParameter(actionIndex: number) {
  	this.actionList.actions[actionIndex].trackingParameters.push(
  		<OHNElement>{
  			element_slug: "0",
				controller: "numberParameterController",
				text: "",
				value: 0,
				prescribed: 0,
        id : OhnService.getUniqueId(this.actionList.actions[actionIndex].trackingParameters)
  		}
  	);
  }

  deleteTrackingParameter(actionIndex: number, parameterIndex: number){
  	this.actionList.actions[actionIndex].trackingParameters.splice(parameterIndex, 1);
  }
}
