import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';

@Component({
  selector: 'app-fitbit-device-pairing',
  templateUrl: './fitbit-device-pairing.component.html',
  styleUrls: ['./fitbit-device-pairing.component.scss'],
})
export class FitbitDevicePairingComponent implements OnInit {

	@Input() el: OHNElement;

	pairingCode : string = "******";

	loading : boolean = false;

  constructor(
  	private ohnApi: OhnApiService
  ) {}

  ngOnInit() {}

  getPairingCode() {
  	this.loading = true;
  	this.ohnApi.getFitbitPairingCode().subscribe(code => {
      console.log(code);
      this.pairingCode = code.device_code;
      this.loading = false;
    });
  }

}
