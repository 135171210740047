import { Component, OnInit, Input} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement ,OHNCalendarAction, OHNCalendarActionList, OHNMedication, OHNMeeting } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-new-meeting',
  templateUrl: './new-meeting.component.html',
  styleUrls: ['./new-meeting.component.scss'],
})
export class NewMeetingComponent implements OnInit {

	@Input() user: OHNUser;
	@Input() calendarElement : OHNElement;
  @Input() isNew: boolean;
  @Input() editMeeting: OHNMeeting;

  meeting: OHNMeeting;

	eventModified: boolean = false;

	loading: boolean = false;

  weekDaysToTake: boolean[] = [false, false, false, false, false, false, false];

  meetingTimes : any[] = [];

  daily: boolean = false;

  constructor(
  	public modalController: ModalController,
    public toastController: ToastController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService
  ) { }

  ngOnInit() {
  	this.loadDetailedCalendarElement();
    this.meeting = this.editMeeting || new OHNMeeting();
    if (this.isNew){
      this.meeting.startTime = new Date().toISOString();
      this.meeting.endTime = new Date().toISOString();
    } else {
      this.setWeekdaysAndIntakeTimes();
    }
  }

  setWeekdaysAndIntakeTimes() {
    this.meeting.cron.weekday.forEach(w => { this.weekDaysToTake[w] = true; });
    this.daily = _.filter(this.weekDaysToTake, (d)=>{return !d;}) ? false : true;
    this.meeting.cron.hour.forEach((h,i) => {
      let tmpDate : Date = new Date();
      tmpDate.setHours(h);
      tmpDate.setMinutes(this.meeting.cron.minute[i]);
      this.meetingTimes.push({value : tmpDate.toISOString()});
    });
  }

  dailySwitched(event: any) {
    console.log(event.detail.checked);
    if (event.detail.checked) {
      this.weekDaysToTake.forEach((w,i) => { this.weekDaysToTake[i] = true; });
    } else {
      this.weekDaysToTake.forEach((w,i) => { this.weekDaysToTake[i] = false; });
    }
  }

  addIntakeTime() {
    this.meetingTimes.push({value : "2020-01-01T08:00:00"});
  }

  deleteIntakeTime(index: number) {
    this.meetingTimes.splice(index, 1);
  }

  loadDetailedCalendarElement(){
  	this.loading = true;
  	this.ohnApi.getElement(this.calendarElement.element_slug, 3).subscribe(calendar => {
  		this.calendarElement = calendar;
  		this.loading = false;
  	});
  }

  save() {
  	this.loading = true;
    this.meeting.content = JSON.stringify(this.meeting.contentObject);
    this.meeting.formatCron(this.weekDaysToTake, this.meetingTimes);

     if (this.isNew) {    
      this.ohnApi.setElementStateSc(this.calendarElement.element_slug, {value: this.meeting}, this.user.smart_contract).subscribe(event => {
        this.meeting = new OHNMeeting(event);
        this.loading = false;
        this.eventModified = true;
        this.notifyOfSaved();
      });
    } else {
      this.ohnApi.patchElementState(this.calendarElement.element_slug, {value: this.meeting}).subscribe(state => {
        this.loading = false;
        this.eventModified = true;
        this.notifyOfSaved();
      });
    }
  }


  async notifyOfSaved() {
  	const toast = await this.toastController.create({
      message: 'Event has been saved.',
      duration: 2000
    });
    toast.present();
  }

  closeModal() {
  	if (this.eventModified) {
  		this.modalController.dismiss({
	      dismissed: true,
	      event: this.meeting,
        isNew: this.isNew
	    });
  	} else {
  		this.modalController.dismiss({
	      dismissed: true
	    });
  	}
  }

}