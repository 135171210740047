import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, ToastController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { NewMedicationComponent } from '../new-medication/new-medication.component';
import { OHNElement, OHNUser, OHNCalendarEvent, OHNMedication } from '../../models/ohn-instances';
import { Subject} from 'rxjs';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-prescription-editor',
  templateUrl: './prescription-editor.component.html',
  styleUrls: ['./prescription-editor.component.scss'],
})
export class PrescriptionEditorComponent implements OnInit {

	@Input() user: OHNUser;
	@Input() medCalendarObject: OHNElement;
  @Input() calendarUpdater: Subject<any>;

  prescriptions : OHNMedication[];

  loading : boolean = true;

  constructor(
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
    private modalController : ModalController,
    private alertCtrl: AlertController,
    private toastController: ToastController
  ) { }

  ngOnInit() {
    this.loading = true;
    this.loadPrescriptions();
  }

  loadPrescriptions() {
    this.ohnApi.getElementStateRepeatableSc(this.medCalendarObject.element_slug, this.user.smart_contract).subscribe(state => {
      this.prescriptions = (state.value &&_.isArray(state.value)) ? _.map(state.value, (s)=>{return new OHNMedication(s)}) : [];
      console.log(this.prescriptions);
      this.loading = false;
    });
  }

  async openMedModal(isNew: boolean, med: OHNMedication) {
    const editMedPage = await this.modalController.create({
      component: NewMedicationComponent,
      componentProps: {
        'calendarElement' : this.medCalendarObject,
        'user' : this.user,
        'isNew' : isNew,
        'editMed' : med
      },
      backdropDismiss: false
    });

    editMedPage.onDidDismiss().then((data) => {
      this.calendarUpdater.next();
      this.ohnService.widgetRefresher.next();
      if (data.data.isNew) {
        this.prescriptions.push(data.data.event)
      }
    })

    return await editMedPage.present();
  }

  async showDeleteDialog(prescription : OHNMedication) {
   const alert = await this.alertCtrl.create({
      header: 'Delete Medication',
      message: 'Are you sure you want to delete <strong>'+ prescription.title +'</strong>?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
           
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.delete(prescription);
          }
        }
      ]
    });

    await alert.present();
  }

  async notifyOfDeletion(prescription: OHNMedication) {
    const toast = await this.toastController.create({
      message: 'Medication <strong>'+ prescription.title +'</strong> has been deleted.',
      duration: 2000
    });
    toast.present();
  }

  delete(prescription: OHNMedication){
    this.prescriptions = _.filter(this.prescriptions, (p)=>{return p.id != prescription.id});
    this.ohnApi.deleteElementStateSc(this.medCalendarObject.element_slug, {value: {id : prescription.id}}, this.user.smart_contract).subscribe(event => {
      this.notifyOfDeletion(prescription);
    });
  }

}